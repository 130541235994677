import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/passPage/NotFound.vue'
import PrivacyPolicy from '../views/passPage/PrivacyPolicy.vue'
import UserAgreement from '../views/passPage/UserAgreement.vue'
import DownPage from '../views/DownPage.vue'
import ShareUserAgreement from '../views/ShareUserAgreement.vue'
import ShareUserRule from '../views/ShareUserRule.vue'
import ApplePage from '../views/ApplePage.vue'
import PunishPage from '../views/banner/PunishPage.vue'
import StandardPage from '../views/banner/StandardPage.vue'
import GuidePage from '../views/passPage/GuidePage.vue'
import LoveStrategy from '../views/passPage/LoveStrategy.vue'
import NetNotice from '../views/passPage/NetNotice.vue'
import PreventionGuide from '../views/passPage/PreventionGuide.vue'
import VipAgreement from '../views/passPage/VipAgreement.vue'
// import InviteHome from '../views/invite/InviteHome.vue'
// import InviteBase from '../views/invite/InviteBase.vue'
// import CashPage from '../views/invite/CashPage.vue'
// import CashRecord from '../views/invite/CashRecord.vue'
// import InviteRecord from '../views/invite/InviteRecord.vue'
import CollectHome from '../views/collect/CollectHome.vue'
import CollectBase from '../views/collect/CollectBase.vue'
import CollectUserInfo from '../views/collect/CollectUserInfo.vue'
import UserName from '../views/collect/user/UserName.vue'
import UserAge from '../views/collect/user/UserAge.vue'
import UserSex from '../views/collect/user/UserSex.vue'
import UserPhone from '../views/collect/user/UserPhone.vue'
import UserZfb from '../views/collect/user/UserZfb.vue'
import UserJob from '../views/collect/user/UserJob.vue'
import UserHeight from '../views/collect/user/UserHeight.vue'
import UserWeight from '../views/collect/user/UserWeight.vue'
import UserSign from '../views/collect/user/UserSign.vue'
import UserDesc from '../views/collect/user/UserDesc.vue'
import CollectUserCert from '../views/collect/CollectUserCert.vue'
import UserCert from '../views/collect/user/UserCert.vue'
import CollectUserFace from '../views/collect/CollectUserFace.vue'
import UserFace from '../views/collect/user/UserFace.vue'
import CollectUserAuth from '../views/collect/CollectUserAuth.vue'
import UserAvatar from '../views/collect/user/UserAvatar.vue'
import UserNickname from '../views/collect/user/UserNickname.vue'
import CollectUserPay from '../views/collect/CollectUserPay.vue'
import UserOrder from '../views/collect/user/UserOrder.vue'
import CollectUserLocation from '../views/collect/CollectUserLocation.vue'
import UserLocation from '../views/collect/user/UserLocation.vue'
import CollectUserDevice from '../views/collect/CollectUserDevice.vue'
import DeviceNumber from '../views/collect/device/DeviceNumber.vue'
import DeviceSystem from '../views/collect/device/DeviceSystem.vue'
import DeviceConfig from '../views/collect/device/DeviceConfig.vue'
import DeviceUnique from '../views/collect/device/DeviceUnique.vue'
import DeviceCode from '../views/collect/device/DeviceCode.vue'
import DeviceAddress from '../views/collect/device/DeviceAddress.vue'
import DeviceIdfv from '../views/collect/device/DeviceIdfv.vue'
import DeviceIdfa from '../views/collect/device/DeviceIdfa.vue'
import DeviceOnei from '../views/collect/device/DeviceOnei.vue'
import DeviceUuid from '../views/collect/device/DeviceUuid.vue'
import DeviceOaid from '../views/collect/device/DeviceOaid.vue'
import DeviceAndroid from '../views/collect/device/DeviceAndroid.vue'
import DeviceImei from '../views/collect/device/DeviceImei.vue'
import DeviceMac from '../views/collect/device/DeviceMac.vue'
import CollectUserConnect from '../views/collect/CollectUserConnect.vue'
import DeviceOperator from '../views/collect/device/DeviceOperator.vue'
import SdkInfo from '../views/passPage/SdkInfo.vue'
import DeviceIccid from '../views/collect/device/DeviceIccid.vue'
import DeviceBssid from '../views/collect/device/DeviceBssid.vue'
import DeviceSsid from '../views/collect/device/DeviceSsid.vue'
import RechargeAgreement from '../views/passPage/RechargeAgreement.vue'
//
const routes = [
  {
    path: '/',
    component: HomeView,
    meta: {
      title: '面具'
    },
    children: [
    ]
  },
  {
    path: '/down-page/:code?',
    name: 'DownPage',
    component: DownPage,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/share-user-agreement',
    name: 'ShareUserAgreement',
    component: ShareUserAgreement,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/share-user-rule',
    name: 'ShareUserRule',
    component: ShareUserRule,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/apple-down',
    name: 'apple',
    component: ApplePage,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/punish-page',
    name: 'PunishPage',
    component: PunishPage,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/standard-page',
    name: 'StandardPage',
    component: StandardPage,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/sdk-info',
    name: 'SdkInfo',
    component: SdkInfo,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/user-agreement',
    name: 'UserAgreement',
    component: UserAgreement
  },
  {
    path: '/recharge-agreement',
    name: 'RechargeAgreement',
    component: RechargeAgreement
  },
  {
    path: '/vip-agreement',
    name: 'VipAgreement',
    component: VipAgreement
  },
  {
    path: '/guide-page/:id?',
    name: 'GuidePage',
    component: GuidePage,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/prevention-guide',
    name: 'PreventionGuide',
    component: PreventionGuide,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/love-strategy/:id?',
    name: 'LoveStrategy',
    component: LoveStrategy,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/net-notice/:id?',
    name: 'NetNotice',
    component: NetNotice,
    meta: {
      title: '面具'
    }
  },
  {
    path: '/info-collect',
    component: CollectBase,
    children: [
      {
        path: '',
        name: 'CollectHome',
        component: CollectHome
      },
      {
        path: 'user-info',
        children: [
          {
            path: '',
            name: 'CollectUserInfo',
            component: CollectUserInfo
          },
          {
            path: 'name',
            name: 'CollectUserName',
            component: UserName
          },
          {
            path: 'age',
            name: 'CollectUserAge',
            component: UserAge
          },
          {
            path: 'sex',
            name: 'CollectUserSex',
            component: UserSex
          },
          {
            path: 'sex',
            name: 'CollectUserPhone',
            component: UserPhone
          },
          {
            path: 'zfb',
            name: 'CollectUserZfb',
            component: UserZfb
          },
          {
            path: 'job',
            name: 'CollectUserJob',
            component: UserJob
          },
          {
            path: 'height',
            name: 'CollectUserHeight',
            component: UserHeight
          },
          {
            path: 'weight',
            name: 'CollectUserWeight',
            component: UserWeight
          },
          {
            path: 'sign',
            name: 'CollectUserSign',
            component: UserSign
          },
          {
            path: 'desc',
            name: 'CollectUserDesc',
            component: UserDesc
          }
        ]
      },
      {
        path: 'user-cert',
        children: [
          {
            path: '',
            name: 'CollectUserCert',
            component: CollectUserCert
          },
          {
            path: 'cert',
            name: 'CollectUserCerts',
            component: UserCert
          }
        ]
      },
      {
        path: 'user-face',
        children: [
          {
            path: '',
            name: 'CollectUserFace',
            component: CollectUserFace
          },
          {
            path: 'face',
            name: 'CollectUserFaces',
            component: UserFace
          }
        ]
      },
      {
        path: 'user-auth',
        children: [
          {
            path: '',
            name: 'CollectUserAuth',
            component: CollectUserAuth
          },
          {
            path: 'avatar',
            name: 'CollectUserAvatar',
            component: UserAvatar
          },
          {
            path: 'nickname',
            name: 'CollectUserNickname',
            component: UserNickname
          }
        ]
      },
      {
        path: 'user-pay',
        children: [
          {
            path: '',
            name: 'CollectUserPay',
            component: CollectUserPay
          },
          {
            path: 'order',
            name: 'CollectUserOrder',
            component: UserOrder
          }
        ]
      },
      {
        path: 'user-location',
        children: [
          {
            path: '',
            name: 'CollectUserLocation',
            component: CollectUserLocation
          },
          {
            path: 'location',
            name: 'CollectUserIndex',
            component: UserLocation
          }
        ]
      },
      {
        path: 'user-device',
        children: [
          {
            path: '',
            name: 'CollectUserDevice',
            component: CollectUserDevice
          },
          {
            path: 'number',
            name: 'CollectDeviceNumber',
            component: DeviceNumber
          },
          {
            path: 'system',
            name: 'CollectDeviceSystem',
            component: DeviceSystem
          },
          {
            path: 'config',
            name: 'CollectDeviceConfig',
            component: DeviceConfig
          },
          {
            path: 'unique',
            name: 'CollectDeviceUnique',
            component: DeviceUnique
          },
          {
            path: 'code',
            name: 'CollectDeviceCode',
            component: DeviceCode
          },
          {
            path: 'address',
            name: 'CollectDeviceAddress',
            component: DeviceAddress
          },
          {
            path: 'idfv',
            name: 'CollectDeviceIdfv',
            component: DeviceIdfv
          },
          {
            path: 'idfa',
            name: 'CollectDeviceIdfa',
            component: DeviceIdfa
          },
          {
            path: 'onei',
            name: 'CollectDeviceOnei',
            component: DeviceOnei
          },
          {
            path: 'uuid',
            name: 'CollectDeviceUuid',
            component: DeviceUuid
          },
          {
            path: 'oaid',
            name: 'CollectDeviceOaid',
            component: DeviceOaid
          },
          {
            path: 'android',
            name: 'CollectDeviceAndroid',
            component: DeviceAndroid
          },
          {
            path: 'imei',
            name: 'CollectDeviceImei',
            component: DeviceImei
          },
          {
            path: 'mac',
            name: 'CollectDeviceMac',
            component: DeviceMac
          },
          {
            path: 'iccid',
            name: 'CollectDeviceIccid',
            component: DeviceIccid
          },
          {
            path: 'bssid',
            name: 'CollectDeviceBssid',
            component: DeviceBssid
          },
          {
            path: 'ssid',
            name: 'CollectDeviceSsid',
            component: DeviceSsid
          }
        ]
      },
      {
        path: 'user-connect',
        children: [
          {
            path: '',
            name: 'CollectUserConnect',
            component: CollectUserConnect
          },
          {
            path: 'operator',
            name: 'CollectDeviceOperator',
            component: DeviceOperator
          }
        ]
      }
    ]
  },
  // {
  //   path: '/invite-home',
  //   component: InviteBase,
  //   children: [
  //     {
  //       path: '',
  //       name: 'InviteHome',
  //       component: InviteHome
  //     },
  //     {
  //       path: '/cash-page',
  //       name: 'CashPage',
  //       component: CashPage
  //     },
  //     {
  //       path: '/cash-record',
  //       name: 'CashRecord',
  //       component: CashRecord
  //     },
  //     {
  //       path: '/invite-record',
  //       name: 'InviteRecord',
  //       component: InviteRecord
  //     }
  //   ]
  // },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// router.beforeEach(() => {
//   document.body.scrollTop = 0
//   document.documentElement.scrollTop = 0
//   window.pageXOffset = 0
// })
export default router
